import { AppConfig } from '@/utils/AppConfig'
import { NextSeo } from 'next-seo'
import Head from 'next/head'
// import router from 'next/router'
// import { useRouter } from 'next/router'
// import { cmsBlock } from '@/services'
// import { useEffect } from 'react'

export type MetaProps = {
  title: string
  description: string
  canonical?: string
  image?: string
  keywords?: string
}

const Meta = (props: MetaProps) => {
  // const router = useRouter()

  return (
    <>
      <Head>
        <title>{props.title}</title>
        <meta charSet="UTF-8" key="charset" />
        <meta name="viewport" content="width=device-width,initial-scale=1" key="viewport" />
        <link rel="apple-touch-icon" href="https://cinestar.com.vn/pictures/logo/favicon.ico" key="apple" />
        {/* <link rel="apple-touch-icon" href={`${router.basePath}/apple-touch-icon.png`} key="apple" /> */}
        {/* <link rel="icon" type="image/png" sizes="32x32" href={`${router.basePath}/favicon-32x32.png`} key="icon32" />
        <link rel="icon" type="image/png" sizes="16x16" href={`${router.basePath}/favicon-16x16.png`} key="icon16" /> */}
        <link rel="shortcut icon" href="https://cinestar.com.vn/pictures/logo/favicon.ico" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={props.title} />
        <meta name="twitter:description" content={props.description} />
        <meta name="twitter:image" content={props.image || "/assets/images/logo-meta.png"} />
        <meta name="keywords" content={props.keywords} />
      </Head>
      <NextSeo
        title={props.title}
        description={props.description}
        canonical={props.canonical}
        openGraph={{
          title: props.title,
          description: props.description,
          url: props.canonical,
          locale: AppConfig.locale,
          site_name: AppConfig.site_name,
          images: [
            {
              url: props.image || "/assets/images/logo-meta.png",
              width: 1200,
              height: 1800,
              alt: props.title
            }
          ]
        }}
      />
    </>
  )
}

export { Meta }

